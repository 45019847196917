const data = {
  last_update: "2022-03-29",
  total_province: 34,
  provinces: [
    {
      kota: "Jakarta",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Jawa Barat",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Jawa Tengah",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Jawa Timur",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Banten",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Yogyakarta",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Kalimantan Timur",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Bali",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sumatera Utara",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Riau",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sulawesi Selatan",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sumatera Barat",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Nusa Tenggara Timur",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Kalimantan Selatan",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sumatera Selatan",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Lampung",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Kepulauan Riau",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Bangka Belitung",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Kalimantan Barat",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sulawesi Tengah",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Kalimantan Tengah",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sulawesi Utara",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Papua",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Kalimantan Utara",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Aceh",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Jambi",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Nusa Tenggara Barat",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Papua Barat",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Bengkulu",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sulawesi Tenggara",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Maluku",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Sulawesi Barat",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Maluku Utara",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
    {
      kota: "Gorontalo",
      kasus: 0,
      sembuh: 0,
      meninggal: 0,
      dirawat: 0,
    },
  ],
};

export default data;
