import React from "react";
import StyleSpinner from "./Spinner.styled";

function Spinner() {
  return (
    <StyleSpinner>
      <span></span>
    </StyleSpinner>
  );
}
export default Spinner;
