import styled from "styled-components";

const StyledProvinces = styled.div`
  margin: 1rem;
  h3{
    margin-bottom: 2rem;
  }
  /* Medium Screen */
  @media (min-width: 768px) {
  }
  /* Large Screen */
  @media (min-width: 992px) {
    max-width: 1200px;
    margin: 1rem auto;
  }
`;

export default StyledProvinces ;
